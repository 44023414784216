import { FadeOptions, iAudioSystem } from 'modules-core/audioSystem'
import { iUuidSystem } from 'modules-core/uuidSystem/uuidSystem'
import { iSampleSystem } from 'modules-core/sampleSystem/sampleSystem'
import { Element, stripElementDataToParams } from 'modules-core/elementSystem'
import { iAuthSystem } from 'modules-core/authSystem'
import { createAnalyserEffect, createVolumeEffect } from 'modules-core/audioEffectSystem'
import { elementParamsToPlaylistData } from 'modules-core/elementSystem/smart/smartFunctions'
import { Sample } from 'modules-core/sampleSystem'
import { createPlaylistSystem } from './playlistSystem'
import { iEvent } from 'modules-core/utility'
import { OneshotSystem } from './oneshotSystem'
import { ElementVolumeSystem } from './elementVolumeSystem'
import { deepClone } from 'modules-core/utility/ParseExt'
import { ParseExt } from 'modules-core/utility'
import log from 'modules-core/log'
import getServerTime from 'modules-core/utility/serverTime'
import { MathExt } from 'modules-core/utility'
import { source } from 'common-tags'
import { eventSystem } from 'modules-core/eventSystem'
import { clearStrictTimeout, setStrictTimeout } from 'modules-core/utility/strictTimeout'
// Avoid circular import (elementSystem > elementSpawnSystem > player > elementSystem)
// by using the global `syrinscape.player`, instead.
// import player from 'modules-core/player'
import syrinscape from 'app/common/syrinscape'

// Register events.
eventSystem.add('startElement')

const logger = log.getLogger('elementSpawnSystem')

let spawnCount = 0
let averageSpawnDelay = 0
let maxSpawnDelay = 0

interface iArgs {
	authSystem: iAuthSystem
	audioSystem: iAudioSystem
	sampleSystem: iSampleSystem
	uuidSystem: iUuidSystem
	elementMap: Element.MutableMap
	playlistSystem: Element.PlaylistSystem
	oneshotSystem: OneshotSystem
	elementVolumeSystem: ElementVolumeSystem
}


//@ts-ignore injected by webpack
const isDumbMode = typeof WEBPACK_DUMB_MODE === 'undefined'
	? false
	//@ts-ignore injected by webpack
	: WEBPACK_DUMB_MODE === 'true'

export const createElementSpawnSystem = ({
	authSystem, audioSystem, sampleSystem,
	uuidSystem, elementMap, playlistSystem,
	oneshotSystem, elementVolumeSystem }: iArgs) => {

	const { effectSystem } = audioSystem

	const elementParamsToDataDumbMode = async (params: Element.Params) => {
		const response = await authSystem
			.authorizedFetchPath('online/frontend-api/element-params-to-data/', {
				method: 'POST',
				body: JSON.stringify(params)
			})
		if (response.status !== 200)
			throw new Error(`bad response: ${response.status}`)
		return await response.json() as Element.Data
	}

	const elementParamsToDataSmartMode = (params: Element.Params) => ({
		...params,
		uuid: uuidSystem.nextId(),
		playlistData: elementParamsToPlaylistData(params, uuidSystem),
	})

	const handleSampleFinished = (element: Element.Instance, sampleUUID: string) => {
		element.sampleInstanceLookup.delete(sampleUUID)
		if (
			element.alive &&
			element.sampleInstanceLookup.size === 0 &&
			element.sampleFutureDataLookup.size === 0
		) {
			element?.dispose();
		}
	}

	const handleRepeat = (element: Element.Instance) => {
		if (!element.alive)
			return
		const params = system.dataToRepeatParams(element)
		system.spawnElementFromParams(params)
	}

	let analyseElements: boolean = false

	const system = {
		analyseElements,
		init: ({ analyse = false } = {}) => {
			logger.info(`Analyse elements: ${analyse}`)
			analyseElements = analyse
		},
		dataToRepeatParams: (elementData: Element.Data): Element.Params => {
			const lastItem = elementData.playlistData.sampleDataList[elementData.playlistData.sampleDataList.length - 1]
			let nextStart = lastItem.startTime
			if (elementData.playlistParams.sampleDelayPos === 'end')
				nextStart += lastItem.duration
			const newElementParams = stripElementDataToParams(elementData)
			newElementParams.playlistParams.firstSampleDelay = true
			newElementParams.playlistParams.useElementDelay = false

			newElementParams.playedSamples = elementData.playlistData.sampleDataList
				.map(sample => sample.sampleId)
			newElementParams.startTime = nextStart
			return newElementParams
		},
		paramsToData: async (params: Element.Params) => {
			params = deepClone(params)//just to be sure, though we shouldnt need this
			params.playlistParams.sampleParamsPlaylist = playlistSystem.createPlaylist(params)
			const elementData: Element.Data = isDumbMode
				? await elementParamsToDataDumbMode(params)
				: elementParamsToDataSmartMode(params)

			let { startTime, playlistData } = elementData
			if (startTime !== undefined) {
				const lastItem = playlistData.sampleDataList[playlistData.sampleDataList.length - 1]
				const relativeEndTime = lastItem.startTime + lastItem.duration
				const relativeStartTime = getServerTime() - startTime
				const delta = relativeEndTime - relativeStartTime
				if (delta < 0) {
					console.warn('next start time cannot be earlier than playlist duration. consider reducing delay between samples')
					startTime = undefined
				}
			}
			startTime ??= getServerTime()
			playlistData.sampleDataList
				.forEach(item => item.startTime += startTime)
			return elementData
		},
		spawnElementFromParams: async (params: Element.Params) => {
			const elementData = await system.paramsToData(params)
			return system.spawnElementFromData(elementData)
		},
		spawnElementFromData: (data: Element.Data, full: boolean = false) => {
			const now = getServerTime()

			const element = {
				...data,
				alive: true,
			} as Partial<Element.Instance> as Element.Instance

			// Before we create the element, check if there are any existing elements, because
			// we will emit a `startElement` event one time only for the very first element.
			let emitStartElement = syrinscape.player.elementSystem
				.getElementsWithElementId(element.elementId).length === 0
				&& data.playlistData.sampleDataList.some(sample => !sample.manuallyTriggered)

			if (analyseElements) {
				element.analyser = createAnalyserEffect()
			}

			let preset = effectSystem.preset(data.presetName)
			if (preset === undefined) {
				logger.warn(source`
					Unknown effect spawning element, fallback to 'Off'.
					effect:
						name: ${data.presetName}
					element:
						title: ${element.title}
						id: ${element.elementId}
						uuid: ${element.uuid}
				`)
				preset = effectSystem.preset('Off')
			}

			const wetVolume = createVolumeEffect()
			const dryVolume = createVolumeEffect()

			element.setVolume = (volume) => {
				wetVolume.fadeToValue(volume)
				dryVolume.fadeToValue(volume)
			}

			if (element.volume !== undefined)
				elementVolumeSystem.setElementIdVolume(element.elementId, element.volume)
			if (elementVolumeSystem.elementVolumeMap.has(element.elementId)) {
				const volume = elementVolumeSystem.elementVolumeMap.get(element.elementId)
				wetVolume.value = volume
				dryVolume.value = volume
			}

			const wetEffect = data.presetName === 'Off' ?
				createVolumeEffect()//dead end effect
				: effectSystem.effect(preset.effect)
			let removeOneshotVolumeListener
			if (data.type === 'oneshot') {
				removeOneshotVolumeListener = oneshotSystem.createOneshotVolume(wetVolume, dryVolume, element.analyser, wetEffect)
			} else {
				wetVolume.connect(wetEffect)
				if (element.analyser) {
					dryVolume.connect(element.analyser)
					element.analyser.connect(effectSystem.globalVolume)
				} else {
					dryVolume.connect(effectSystem.globalVolume)
				}
			}

			const fadeOptions: FadeOptions = data.crossfade
				? {
					fadeIn: data.crossfade,
					fadeOut: data.crossfade,
				} : undefined

			element.sampleInstanceLookup = new Map<string, Sample.Instance>()
			element.sampleFutureDataLookup = new Map<string, Sample.FutureData>()
			let firstStartTime: number
			element.playlistData.sampleDataList.forEach((sampleData) => {
				if (!firstStartTime || sampleData.startTime < firstStartTime) {
					firstStartTime = sampleData.startTime
				}
				const spawnSample = () => {
					// Ignore stats for resumed samples via `send_full`. This can throw
					// out the average and max delay by a huge amount.
					if (full && sampleData.startTime < now) {
						const scrubbed = (getServerTime() - sampleData.startTime) / 1000
						logger.info(source`
							spawn sample:
								scheduled samples: ${element.sampleFutureDataLookup.size}
								scrubbed seconds: ${scrubbed}
							sample:
								name: ${sampleData.name}
								uuid: ${sampleData.uuid},
							element:
								title: ${element.title}
								id: ${element.elementId}
								uuid: ${element.uuid}
						`)
					} else {
						spawnCount += 1
						// Ignore spawn delay for scrubbed samples.
						let spawnDelay = (getServerTime() - sampleData.startTime) / 1000
						averageSpawnDelay = MathExt.average([spawnDelay], averageSpawnDelay, spawnCount)
						maxSpawnDelay = Math.max(spawnDelay, maxSpawnDelay)
						logger.info(source`
							spawn sample:
								scheduled samples: ${element.sampleFutureDataLookup.size}
								spawned samples: ${spawnCount}
								delay: ${spawnDelay}
								avg delay: ${averageSpawnDelay}
								max delay: ${maxSpawnDelay}
							sample:
								name: ${sampleData.name}
								uuid: ${sampleData.uuid}
							element:
								title: ${element.title}
								id: ${element.elementId}
								uuid: ${element.uuid}
						`)
					}

					sampleData.elementId = element.elementId

					element.sampleFutureDataLookup.delete(sampleData.uuid)
					const sample = sampleSystem.spawnSampleFromData({
						fadeOptions,
						data: sampleData,
						wetOut: wetVolume,
						dryOut: dryVolume,
					})
					sample.audioSource.onPlay.addListener(() =>
						playlistSystem.appendPlayedSamples(element.elementId, sample.sampleId))
					sample.onDispose.addListener(() =>
						handleSampleFinished(element, sample.uuid))
					element.sampleInstanceLookup.set(sample.uuid, sample)
				}
				// If sample starts more than `preloadWindow` seconds in the future, delay
				// spawning to keep the audio node count low. When starting a mood, we might
				// have data for hundreds of samples (e.g. multiple sfx elements, each with a
				// trigger delay of ~1 second). But creating audio nodes in advance to do
				// nothing until their start time is unnecessarily heavy, and might be
				// completely pointless if the element is stopped before they are started. The
				// preload window should be large enough to allow time for preloading a typical
				// number of samples of a typical size on a typical connection. In the future,
				// this window could resize dynamically per user based on their current
				// connection speed and the number and size of samples in the playlist.
				const preloadWindow = 10  // Seconds
				const spawnTime = sampleData.startTime - preloadWindow * 1000
				if (spawnTime > now) {
					element.sampleFutureDataLookup.set(sampleData.uuid, {
						...sampleData,
						timeoutId: setStrictTimeout(
							spawnSample,
							spawnTime - now,
							preloadWindow * 1000 + sampleData.duration,
						),
					})
					logger.debug(source`
						Delay spawning sample for ${(spawnTime - now) / 1000} seconds.
							scheduledSamples: ${element.sampleFutureDataLookup.size}
							spawnedSamples: ${spawnCount}
						sample:
							name: ${sampleData.name}
							uuid: ${sampleData.uuid}
						element:
							title: ${element.title}
							id: ${element.elementId}
							uuid: ${element.uuid}
					`)
				}
				// Otherwise, spawn immediately.
				else {
					spawnSample()
				}
			})
			logger.debug(source`
				spawnElementFromData():
					scheduled samples: ${element.sampleFutureDataLookup.size}
					spawned samples: ${element.sampleInstanceLookup.size}
				element:
					title: ${data.title}
					id: ${data.elementId}
					uuid: ${data.uuid}
			`)

			if (element.playlistParams?.repeat) {
				const firstSample = element.playlistData.sampleDataList[0]
				element.sampleInstanceLookup.get(firstSample.uuid).audioSource
					.onPlay.addListener(() => handleRepeat(element))
			}

			element.dispose = async () => {
				if (!element.alive) {
					logger.warn(source`
						element.dispose(): Already dead! Can only dispose once!
							title: ${element.title}
							id: ${element.elementId}
							uuid: ${element.uuid}
					`)
					return
				}
				logger.debug(source`
					element.dispose():
						title: ${element.title}
						id: ${element.elementId}
						uuid: ${element.uuid}
				`)
				element.alive = false
				removeOneshotVolumeListener?.()
				element.sampleFutureDataLookup.forEach((sampleFutureData) => {
					clearStrictTimeout(sampleFutureData.timeoutId)
				})
				element.sampleFutureDataLookup.clear()
				await Promise.all([...element.sampleInstanceLookup]
					.map(([, sample]) => sample.dispose()))
				// If we are analysing elements, we need to defer disposal until visualisation
				// has stopped.
				const finallyDispose = () => {
					if (element.analyser?.isActive) {
						logger.debug(source`
							element.finallyDispose(): Visualisation still active. Delay.
								title: ${element.title}
								id: ${element.elementId}
								uuid: ${element.uuid}
						`)
						setTimeout(finallyDispose, 100) // Try again until analyser is inactive
					} else {
						wetVolume.disconnectAll()
						dryVolume.disconnectAll()
						element.analyser?.disconnectAll()
						elementMap.mutate(map => map.delete(data.uuid))
					}
				}
				finallyDispose()
			}

			elementMap.mutate(map => map.set(data.uuid, element))

			// Emit `startElement` event.
			if (emitStartElement) {
				eventSystem.dispatch('startElement', {
					elementId: element.elementId,
					timeToFirstSample: Math.max(firstStartTime - now, 0),
				})
			}

			return element
		},
		_elementParamsToDataSmartMode: elementParamsToDataSmartMode,
		_elementParamsToDataDumbMode: elementParamsToDataDumbMode,
	}
	return system
}

export type iElementSpawnSystem = ReturnType<typeof createElementSpawnSystem>
